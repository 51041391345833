/* RESET & BASIC STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --white: #ffffff;
  --black: #000000;

  /* officefruit */
  --green: #61B63F;
  --green_accent: #DDF0D5;

  /* fruit */
  --red: #df0f36;
  --red_accent: #fce7eb;

  /* milk */
  --blue: #56bae5;
  --blue_accent: #E1EAEE;

  /* bread */
  --brown: #966014;
  --brown_accent: #f5efe8;

  /* coffee */
  --coffee: #be9546;
  --coffee_accent: #f9f4ed;

  /* cakes */
  --purple: #a927aa;
  --purple_accent: #f6e9f7;

  /* catering */
  --grey: #9b9a9a;
  --grey_accent: #f5f5f5;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Loew";
}

body {
  margin: 0 auto;
}

input:focus {
    outline: none;
}

button {
  cursor: pointer;
  transition: .25s
}

/* START GENERAL */

.smaller_font {
  font-size: 12px !important;
}

.medium_font {
  font-size: 17.5px !important;
}

.bigger_font {
  font-size: 35px !important;
}

.sites_table th {
  font-size: 12px !important;
  cursor: default;
}

.page_container p:not(.smaller_font),
.sites_table td {
  font-size: 15px !important;
  margin: 0
}

.page_container h1:not(.bigger_font),
.page_container h1:not(.medium_font) {
  font-size: 30px;
}

.main_container {
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: 75px 1fr;
}

.page_container {
  height: 100%;
  width: 100%;
  background-color: var(--grey_accent)
}

/* END GENERAL */


/* START SIDEBAR */

.sidebar_container {
  height: 100%;
  width: 75px;

  position: relative;
}

.sidebar_account {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 20px);
}

.sidebar_centered {
  height: auto;
  width: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sidebar_grid {
  width: 100%;

  display: grid;
  grid-template-rows: repeat(4, 1fr);
  row-gap: 3rem;
}

.sidebar_box {
  width: 100%;
  height: 100%;
  padding: 15px 0;

  position: relative;
}

.sidebar_active,
.sidebar_active i {
  background-color: var(--green);
  color: white;
}

.sidebar_icon {
  font-size: 30px;
  color: var(--black);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* END SIDEBAR */


/* START LOADING */

.loadpage_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--grey_accent);
}

.loadinner_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.load span {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin: 0 5px;
  background-color: #000000;
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation: 1.2s infinite bubble ease-in-out;
  animation: 1.2s infinite bubble ease-in-out;
}
.load span:nth-child(1) {
  background-color: #61B63F;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.load span:nth-child(2) {
  background-color: #75C057;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.load span:nth-child(3) {
  background-color: #89C96F;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.load span:nth-child(4) {
  background-color: #B0DB9F;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@keyframes bubble {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

/* END LOADING */
