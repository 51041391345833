/* RESET & BASIC STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --white: #ffffff;
  --black: #000000;
  --lightgrey: #F7F7F7;

  /* officefruit */
  --green: #61B63F;
  --green_accent: #DDF0D5;

  /* fruit */
  --red: #df0f36;
  --red_accent: #fce7eb;

  /* milk */
  --blue: #56bae5;
  --blue_accent: #E1EAEE;

  /* bread */
  --brown: #966014;
  --brown_accent: #f5efe8;

  /* coffee */
  --coffee: #be9546;
  --coffee_accent: #f9f4ed;

  /* cakes */
  --purple: #a927aa;
  --purple_accent: #f6e9f7;

  /* catering */
  --grey: #9b9a9a;
  --grey_accent: #f5f5f5;

  /* seasonal */
  --pink: #FF0090;
  --pink_accent: #FFE0E5;
}

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0 auto;
}

input:focus {
    outline: none;
}

button {
  cursor: pointer;
  transition: .25s
}

table tbody {
  display: block !important;
  height: calc(100% - 50px) !important;
  overflow-y: scroll !important
}

table thead, table tbody tr {
  display: table;
  width: 100% !important;
  table-layout: fixed !important;
}

/* START GENERAL */

.smaller_font {
  font-size: 12px !important;
}

.small_font {
  font-size: 13px !important;
}

.medium_font {
  font-size: 17.5px !important;
}

.bigger_font {
  font-size: 35px !important;
}

.sites_table th {
  font-size: 12px !important;
  cursor: default;
}

.page_container p:not(.smaller_font, .small_font),
.sites_table td {
  font-size: 15px !important;
  margin: 0
}

.page_container h1:not(.bigger_font),
.page_container h1:not(.medium_font) {
  font-size: 30px;
}



.main_container {
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: 75px 1fr;
  transition: grid-template-columns 0.6s ease
}

.main_container_full {
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: 200px 1fr;
  transition: grid-template-columns 0.6s ease
}

.page_container {
  height: 100%;
  width: 100%;
  background-color: var(--lightgrey)
}

.page_container_cs {
  height: 100%;
  width: 100%;
  background-color: var(--white)
}

.hidden_container {
  opacity: 0.4 !important;
  filter: brightness(90%);
  pointer-events:none !important;
}

.hidden_indcontainer {
  filter: brightness(90%);
  pointer-events:none !important;
}

.hidediv {
  display: none !important;
}

.site_status {
  padding: 5px 15px;
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto 0 0 0;
  white-space: nowrap
}

.site_status p {
  font-weight: 600;
  margin: auto 0
}

.status_week {
  background-color: var(--blue_accent);
  color: var(--blue);
}

.status_pending {
  background-color: var(--blue_accent);
  color: var(--blue);
}

.status_active {
  background-color: var(--green_accent);
  color: var(--green);
}

.status_inactive {
  background-color: var(--red_accent);
  color: var(--red);
}

.deleteBtn {
  background: none;
  border: none;
  transition: .25s;
  font-size: 15px;
}

.deleteBtn:hover {
  color: var(--green)
}

.indsite_container {
  position: absolute;
  right: 0;

  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.indsite_popup {
  width: 40%;
  height: 100%;

  margin: 0 0 0 auto;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}

.indsite_inner {
  padding: 30px;
  position: relative;
  height: calc(100% - 60px);

  display: flex;
  flex-direction: column;
}

.ind_grid {
  display: grid;
  margin-top: 25px;
  grid-template-rows: repeat(2, 1fr);
  height: calc(100% - 50px);
}

.indinfo_grid {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  word-break: break-all;
}

.indinfo_grid_multi {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem
}

.indsiteinfo_gap {
  row-gap: 2rem
}

.sitesind_thead th,
.sitesind_tr:hover {
  background-color: var(--grey_accent) !important;
}

.notification {
  padding: 10px;
  margin: 10px 0;
  color: white;
  text-align: center;
  font-weight: 600;
  border: none;
  width: 100%;
  box-sizing: border-box;
}

.validationError {
  background-color: var(--red);
}

.confirmMessage {
  background-color: var(--green_accent);
  color: var(--green);
}

.confirmMessage p {
  font-weight: bold
}

.warningMessage {
  background-color: var(--red_accent);
  color: var(--red);
}

.warningMessage span {
  text-decoration: solid underline red 1px;
}

.greenWarningMessage {
  background-color: var(--green);
  color: var(--white);
}

.greenWarningMessage span {
  font-style: italic
}


/* END GENERAL */



/* START POPUP */

.popup_container {
  width: 500px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  border-radius: 10px;
}

.fixed_container_height {
  height: 650px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  border-radius: 10px;
  box-sizing: border-box;
}

.flag_container_height {
  height: 750px;
  width: 650px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  border-radius: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column
}

.popup_relative {
  position: relative;
  height: 100%;
  width: 100%;
}

.popup_scrollable {
  max-height: 50vh;
  overflow-y: auto;
}

.popUpControlsContainer,
.popUpControls {
  display: flex;
  margin: 0 0 0 auto
}

.closePopup {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: white;
  border: none;
}

.closePopup i {
  margin: auto 0
}

.editPopup {
  position: absolute;
  top: 30px;
  right: 50px;
  background-color: white;
  border: none;
}

.editPopup i {
  margin: auto 0
}

.nextPopup {
  position: absolute;
  top: 30px;
  right: 70px;
  background-color: white;
  border: none;
}

.nextPopup i {
  margin: auto 0
}

.otherPopup {
  position: absolute;
  top: 30px;
  right: 90px;
  background-color: white;
  border: none;
}

.otherPopup i {
  margin: auto 0
}

.betweenPopup {
  position: absolute;
  top: 30px;
  right: 97.5px;
  background-color: white;
  border: none;
}

.betweenPopup i {
  margin: auto 0
}



.finPopup {
  position: absolute;
  top: 30px;
  right: 110px;
  background-color: white;
  border: none;
}

.finPopup i {
  margin: auto 0
}

.closePopup:not([disabled]):hover,
.editPopup:not([disabled]):hover,
.nextPopup:not([disabled]):hover,
.otherPopup:not([disabled]):hover,
.finPopup:not([disabled]):hover,
.betweenPopup:not([disabled]):hover {
  color: var(--green)
}

.prevBtn {
  padding: 7.5px 15px;
  background-color: var(--green_accent);
  color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.nextBtn,
.submitBtn {
  padding: 7.5px 15px;
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px
}

.cloneBtn {
  padding: 7.5px 15px;
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto 0 0
}

.popup_form {
  margin: 25px 0;
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 1rem
}

.extraRowGap {
  row-gap: 2.5rem !important;
}

.popup_form_margin {
  margin: 25px 0;
}

.popup_formorder {
  margin: 15px 0 25px 0;
  row-gap: 0;
}

.popup_form_nm {
  margin-top: 1rem;
  display:grid;
  row-gap: 1rem;
}

.popup_form_flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}

.popup_form_flex_rows {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}


.popup_form_multi {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}

.popup_form_coffee {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}

.popup_formbox {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.popup_formbox label {
  font-weight: 600;
  margin-bottom: 2.5px;
}

.popup_formbox input,
.popup_formbox textarea {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 10px
}

.popup_draggable_input {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 10px
}

/* END POPUP */


/* START SIDEBAR */

.sidebar_container {
  height: 100%;
  width: 75px;

  position: relative;
}

.sidebar_account {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);

  width: 75px;
  height: 60px;
}

.sidebar_trash {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -10px);

  width: 75px;
  height: 60px;
}

.sidebar_centered {
  height: auto;
  width: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sidebar_grid {
  width: 100%;

  display: grid;
  grid-template-rows: repeat(6, auto);
  row-gap: 1rem;
}

.sidebar_grid a {
  width: 100%;
  height: 60px;
}

.sidebar_box {
  width: 100%;
  height: 100%;

  position: relative;
}

.sidebar_active,
.sidebar_active i {
  background-color: var(--green);
  color: white;
}

.sidebar_icon {
  font-size: 30px;
  color: var(--black);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* END SIDEBAR */


/* START SITES */

.sites_container {
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: 15% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.comingsoon_container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.csinner_container {
  width: 60%;
}

.csinner_container img {
  width: 100%;
  height: 100%;
}

.flaggedContainer {
  display: flex;
  flex-direction: column;
  row-gap: .1rem
}

.sites_container_with_warning {
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: auto 15% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.sites_container_with_warning_bin {
  max-height: 100vh;
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: auto 15% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.sites_container_with_warnings {
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: auto auto 15% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.sites_container_ongoing {
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: 15% 22.5% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.sitepage_container {
  height: 100%;
  border-bottom: 2px solid var(--white);

  position: relative;
}

.sitenav {
  display: flex;
  position: absolute;
  bottom: -2px;
}

.innersitenav {
  display: flex;
  margin-top: 15px;
}

.indsite_nav {
  position: relative;
  height: 50px;
  margin-bottom: 20px;
}

.sitenav_box {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  transition: border-bottom-color 0.25s;
  border-bottom: 2px solid var(--white);

  position: relative;
}

.sitenav_box:hover {
  border-bottom: 2px solid var(--green)
}

.sitenav_active {
  border-bottom: 2px solid var(--green)
}

.page_heading {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.page_heading h1 {
  margin: 0 auto 0 0;
  width: auto;
}

.page_heading button {
  margin: 0 0 0 auto;
  padding: 7.5px 15px;
  background-color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.page_heading button p,
.page_heading button i {
  color: var(--white);
  font-weight: 600;
  margin: auto 0;
}

.page_heading button i {
  margin-right: 5px;
  font-size: 12px;
}

.page_heading button:hover {
  background-color: white;
  color: var(--green)
}

.page_heading button:hover p,
.page_heading button:hover i {
  color: var(--green)
}


.sites_stats {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem
}

.statbox {
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;
}

.sites_tablecontainer {
  height: 100%;
  width: 100%;
/*  border-radius: 15px;*/

  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 10px;

  flex-grow: 1;
  overflow-y: auto;
  padding-right: 15px;
}

.sites_tablecontainer_orders {
  height: 100%;
  width: 100%;
/*  border-radius: 15px;*/

  display: grid;
/*  grid-template-rows: auto 1fr;*/
/*  row-gap: 10px;*/

  flex-grow: 1;
  overflow-y: auto;
  padding-right: 15px;
  padding-bottom: 5px;
}

.table_container {
  height: 100%;
}

.indtbody {
  position: relative;
}

.sites_controls {
  display: flex;
  width: 100%;
}

.sites_filters {
  margin: 0 auto 0 0;
  display: flex;
}

.filter_add {
  padding: 7.5px 15px;
  border: 2px solid var(--white);
  border-radius: 5px;
  display: flex;

  align-items: center;
  justify-content:center;

  margin-right: 10px;
}

.filter_add p {
  margin: 0;
  font-weight: 600;
}

.filter_add i {
  font-size: 12px;
  margin: 0;
  margin-right: 7.5px;
}

.filter_active {
  padding: 7.5px 15px;
  background-color: var(--green_accent);
  border-radius: 5px;
  display: flex;

  align-items: center;
  justify-content:center;

  margin-right: 10px;

}

.filter_active p {
  margin: 0;
  color: var(--green);
  font-weight: 600;
  margin-right: 7.5px;
}

.filter_active i {
  font-size: 12px;
  margin: 0;
  color: var(--green)
}

.admin_searchcontainer {
  padding: 10px 0;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;

  display: flex;
  align-items: center;
}

.sites_searchcontainer {
  padding: 10px 0;
  width: 350px;
  background-color: var(--white);
  border-radius: 5px;

  display: flex;
  align-items: center;
}

.sites_search {
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%
}

.sites_search input {
  border: none;
  margin: auto 0;
  padding-left: 10px;
  font-weight: 600;
  width: 100%;
  font-size: 15px;
}

.sites_search i {
  font-size: 15px;
  font-weight: 600;
}

.sites_sort {
  display: flex;
}

.sites_table {
  border-collapse: collapse;

  width: 100%;
  height: 100%;
  text-align: left;

}

.sites_table th {
  padding: 15px;
}

.sites_table tr {
  counter-increment: counter;
  cursor: pointer;
  transition: .25s
}

.sites_table tr:hover:not(.table_rowth) {
  background-color: white;
}

.table_rowth {
  background-color: white;
}


.sites_table td {
  width: auto;
  padding: 30px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fleet_container_prefs td {
  width: 200px !important;
}

.tool_btn {
  background: none;
  border: none;
}

.tool_btn:nth-child(2) {
  margin-left: 10px;
}


.tool_btn i {
  font-size: 15px;
}

.tool_btn:hover {
  color: var(--green)
}

.progressbar {
  padding: 5px 50px;
  border-radius: 100px;
  background: var(--green);
}

.temp_progressbar1 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 50%, var(--green_accent) 50%, var(--green_accent) 100%);
}

.temp_progressbar2 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 30%, var(--green_accent) 30%, var(--green_accent) 100%);
}

.temp_progressbar3 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 80%, var(--green_accent) 80%, var(--green_accent) 100%);
}

.indsitebtn {
  margin: 0 0 0 auto;
  padding: 7.5px 15px;
  background-color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.indsitebtn p,
.indsitebtn i {
  color: var(--white);
  font-weight: 600;
}

.indsitebtn i {
  margin-right: 5px;
  font-size: 12px;
}

.indsitebtn:hover {
  background-color: white;
  color: var(--green)
}

.indsitebtn:hover p,
.indsitebtn:hover i {
  color: var(--green)
}

.indsite_controls_wh {
  white-space: nowrap;
  align-items: center;
}

.autowidth {
  width: 100%;
}

.indorder_seasonal {
  grid-template-rows: auto auto 1fr !important;
}

.indorder_seasonal_combined {
/*  grid-template-rows: auto auto auto 1fr !important;*/
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}

.indorder_seasonal_hig {
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
}

.box_layout {
  display: flex;
  column-gap: .5rem;
}

.view_order {
  margin: 0 0 0 auto;
  width: max-content;
}

.box_active {
  background-color: var(--green) !important;
  color: var(--green_accent) !important;
}

.week_active {
  background-color: var(--blue) !important;
  color: var(--blue_accent) !important;
}

/* END SITES */


/* START CUSTOM ORDERS */

/* Fruit */
.fruit_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--red);
  color: var(--white) !important;
}

.fruit_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.fruit_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.fruit_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--red) !important;
}

.fruit_container .order_box_active:hover {
  background-color: var(--red)
}

.fruit_container .order_box_active:hover i,
.fruit_container .order_box_active:hover p:not(.site_status p),
.fruit_container .order_box_active:hover h1,
.fruit_container .order_box_active:hover label {
  color: var(--white)
}

.fruit_container .filter_active {
  background-color: var(--red_accent) !important;
}

.fruit_container .filter_active p,
.fruit_container .filter_active i {
  color: var(--red) !important
}

.fruit_container .sitenav_active {
  border-bottom: 2px solid var(--red)
}

.fruit_container .sitenav_box:hover {
  border-bottom: 2px solid var(--red)
}

.fruit_container .progressbar {
  background: linear-gradient(to right, var(--red) 0%, var(--red) 50%, var(--red_accent) 50%, var(--red_accent) 100%);
}

.fruit_container .progressdot,
.fruit_container .progressdot_box::before,
.fruit_container .progressdot_box_down::before,
.fruit_container .progressdot_box_up::before {
  background-color: var(--red)
}

.fruit_container .progress_text,
.fruit_container .sliderBtn {
  color: var(--red)
}

/* Milk */
.milk_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--blue);
  color: var(--white) !important;
}

.milk_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.milk_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.milk_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--blue) !important;
}

.milk_container .order_box_active:hover {
  background-color: var(--blue)
}

.milk_container .order_box_active:hover i,
.milk_container .order_box_active:hover p:not(.site_status p),
.milk_container .order_box_active:hover h1,
.milk_container .order_box_active:hover label {
  color: var(--white)
}

.milk_container .filter_active {
  background-color: var(--blue_accent) !important;
}

.milk_container .filter_active p,
.milk_container .filter_active i {
  color: var(--blue) !important
}

.milk_container .sitenav_active {
  border-bottom: 2px solid var(--blue)
}

.milk_container .sitenav_box:hover {
  border-bottom: 2px solid var(--blue)
}

.milk_container .progressbar {
  background: linear-gradient(to right, var(--blue) 0%, var(--blue) 50%, var(--blue_accent) 50%, var(--blue_accent) 100%);
}

.milk_container .progressdot,
.milk_container .progressdot_box::before,
.milk_container .progressdot_box_down::before,
.milk_container .progressdot_box_up::before {
  background-color: var(--blue)
}

.milk_container .progress_text,
.milk_container .sliderBtn {
  color: var(--blue)
}

/* Bread */
.bread_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--brown);
  color: var(--white) !important;
}

.bread_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.bread_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.bread_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--brown) !important;
}

.bread_container .order_box_active:hover {
  background-color: var(--brown)
}

.bread_container .order_box_active:hover i,
.bread_container .order_box_active:hover p:not(.site_status p),
.bread_container .order_box_active:hover h1,
.bread_container .order_box_active:hover label {
  color: var(--white)
}

.bread_container .filter_active {
  background-color: var(--brown_accent) !important;
}

.bread_container .filter_active p,
.bread_container .filter_active i {
  color: var(--brown) !important
}

.bread_container .sitenav_active {
  border-bottom: 2px solid var(--brown)
}

.bread_container .sitenav_box:hover {
  border-bottom: 2px solid var(--brown)
}

.bread_container .progressbar {
  background: linear-gradient(to right, var(--brown) 0%, var(--brown) 50%, var(--brown_accent) 50%, var(--brown_accent) 100%);
}

.bread_container .progressdot,
.bread_container .progressdot_box::before,
.bread_container .progressdot_box_down::before,
.bread_container .progressdot_box_up::before {
  background-color: var(--brown)
}

.bread_container .progress_text,
.bread_container .sliderBtn {
  color: var(--brown)
}

/* Coffee */
.coffee_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--coffee);
  color: var(--white) !important;
}

.coffee_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.coffee_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.coffee_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--coffee) !important;
}

.coffee_container .order_box_active:hover {
  background-color: var(--coffee)
}

.coffee_container .order_box_active:hover i,
.coffee_container .order_box_active:hover p:not(.site_status p),
.coffee_container .order_box_active:hover h1,
.coffee_container .order_box_active:hover label {
  color: var(--white)
}

.coffee_container .filter_active {
  background-color: var(--coffee_accent) !important;
}

.coffee_container .filter_active p,
.coffee_container .filter_active i {
  color: var(--coffee) !important
}

.coffee_container .sitenav_active {
  border-bottom: 2px solid var(--coffee)
}

.coffee_container .sitenav_box:hover {
  border-bottom: 2px solid var(--coffee)
}

.coffee_container .progressbar {
  background: linear-gradient(to right, var(--coffee) 0%, var(--coffee) 50%, var(--coffee_accent) 50%, var(--coffee_accent) 100%);
}

.coffee_container .progressdot,
.coffee_container .progressdot_box::before,
.coffee_container .progressdot_box_down::before,
.coffee_container .progressdot_box_up::before {
  background-color: var(--coffee)
}

.coffee_container .progress_text,
.coffee_container .sliderBtn {
  color: var(--coffee)
}

/* Cakes */
.cakes_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--purple);
  color: var(--white) !important;
}

.cakes_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.cakes_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.cakes_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--purple) !important;
}

.cakes_container .order_box_active:hover {
  background-color: var(--purple)
}

.cakes_container .order_box_active:hover i,
.cakes_container .order_box_active:hover p:not(.site_status p),
.cakes_container .order_box_active:hover h1,
.cakes_container .order_box_active:hover label {
  color: var(--white)
}

.cakes_container .filter_active {
  background-color: var(--purple_accent) !important;
}

.cakes_container .filter_active p,
.cakes_container .filter_active i {
  color: var(--purple) !important
}

.cakes_container .sitenav_active {
  border-bottom: 2px solid var(--purple)
}

.cakes_container .sitenav_box:hover {
  border-bottom: 2px solid var(--purple)
}

.cakes_container .progressbar {
  background: linear-gradient(to right, var(--purple) 0%, var(--purple) 50%, var(--purple_accent) 50%, var(--purple_accent) 100%);
}

.cakes_container .progressdot,
.cakes_container .progressdot_box::before,
.cakes_container .progressdot_box_down::before,
.cakes_container .progressdot_box_up::before {
  background-color: var(--purple)
}

.cakes_container .progress_text,
.cakes_container .sliderBtn {
  color: var(--purple)
}

/* Catering */
.catering_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--grey);
  color: var(--white) !important;
}

.catering_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.catering_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.catering_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--grey) !important;
}

.catering_container .order_box_active:hover {
  background-color: var(--grey)
}

.catering_container .order_box_active:hover i,
.catering_container .order_box_active:hover p:not(.site_status p),
.catering_container .order_box_active:hover h1,
.catering_container .order_box_active:hover label {
  color: var(--white)
}

.catering_container .filter_active {
  background-color: var(--grey_accent) !important;
}

.catering_container .filter_active p,
.catering_container .filter_active i {
  color: var(--grey) !important
}

.catering_container .sitenav_active {
  border-bottom: 2px solid var(--grey)
}

.catering_container .sitenav_box:hover {
  border-bottom: 2px solid var(--grey)
}

.catering_container .progressbar {
  background: linear-gradient(to right, var(--grey) 0%, var(--grey) 50%, var(--grey_accent) 50%, var(--grey_accent) 100%);
}

.catering_container .progressdot,
.catering_container .progressdot_box::before,
.catering_container .progressdot_box_down::before,
.catering_container .progressdot_box_up::before {
  background-color: var(--grey)
}

.catering_container .progress_text,
.catering_container .sliderBtn {
  color: var(--grey)
}

/* Seasonal */
.seasonal_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--pink);
  color: var(--white) !important;
}

.seasonal_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.seasonal_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.seasonal_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--pink) !important;
}

.seasonal_container .order_box_active:hover {
  background-color: var(--pink)
}

.seasonal_container .order_box_active:hover i,
.seasonal_container .order_box_active:hover p:not(.site_status p),
.seasonal_container .order_box_active:hover h1,
.seasonal_container .order_box_active:hover label {
  color: var(--white)
}

.seasonal_container .filter_active {
  background-color: var(--pink_accent) !important;
}

.seasonal_container .filter_active p,
.seasonal_container .filter_active i {
  color: var(--pink) !important
}

.seasonal_container .sitenav_active {
  border-bottom: 2px solid var(--pink)
}

.seasonal_container .sitenav_box:hover {
  border-bottom: 2px solid var(--pink)
}

.seasonal_container .progressbar {
  background: linear-gradient(to right, var(--pink) 0%, var(--pink) 50%, var(--pink_accent) 50%, var(--pink_accent) 100%);
}

.seasonal_container .progressdot,
.seasonal_container .progressdot_box::before,
.seasonal_container .progressdot_box_down::before,
.seasonal_container .progressdot_box_up::before {
  background-color: var(--pink)
}

.seasonal_container .progress_text,
.seasonal_container .sliderBtn {
  color: var(--pink)
}

.orders_current_container {
  height: 100%;
  width: 100%;
  display: grid;

  grid-template-columns: 50px calc(100% - 100px) 50px;
}

.orders_current_outer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.orders_current {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 1fr;
  column-gap: 2rem;
}

.sliderBtn {
  border: none;
  background: none;
  font-size: 20px;
}

.order_box,
.order_box_active,
.order_box_progress {
  background-color: var(--white);
  border-radius: 10px;
  padding: 15px;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
  border: none;
  text-align: left;
  position: relative;
}

.create_first {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
}


.create_first2 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order_boxinfo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 1rem
}

.order_header {
  display: flex;
}

.order_header h1 {
  margin: 0 auto 0 0;
}

.order_header .popup_formbox {
  margin: 0 0 0 auto;
}

.site_contact_flex {
  display: flex
}

.site_contact_flex i {
  margin: auto 0;
  font-size: 12px;
  margin-right: 10px
}

.popup_formbox label,
.site_contact_flex label {
  font-weight: 600;
  margin-bottom: 2.5px;
}

.popup_formbox input,
.site_contact_flex input {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 10px
}

.site_statustd {
  display: flex;
}

.order_typebox {
  width: 100%;
  padding: 5px 10px 5px 0;
  border: 1px solid var(--red_accent);
  background: none;
  border-radius: 5px;
  text-align: left;

  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: .5rem;
}

.order_typebox:disabled {
  cursor: default;
}

.order_catbox:disabled {
  cursor: default;
  opacity: .25
}

.order_typebox:enabled:hover {
  border: 1px solid var(--red);
}

.order_typeboxactive {
  border: 1px solid var(--red);
}


.order_catbox {
  width: 100%;
  padding: 5px 10px 5px 0;
  border: none;
  background: none;
  border-radius: 5px;
  text-align: left;
  display: flex;
  justify-content: center;
}

.order_catimgbox {
  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.order_catimgbox img {
  height: 100%;
  margin: auto 0
}

.order_catbox:enabled:hover {
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}

.order_catboxactive {
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}


.order_imgbox {
  width: 100px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.order_imgbox img {
  height: 100%;
  margin: auto 0
}

.order_contentbox {
  margin: auto 0
}

.progressmap {
  display: grid;
  grid-template-columns: auto 10% 1fr auto;
  grid-template-rows: repeat(4, 1fr);
  column-gap: .5rem
}

.progressmap p {
  font-weight: 700;
  margin: auto 0;
}

.progressdot_box,
.progressdot_box_up,
.progressdot_box_down {
  position: relative;
  height: 100%; /* adjust the height according to your needs */
  width: 100%;
}

.progressdot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: red;
  z-index: 2;
}

.progressdot_map {
  position: absolute;
  top: 100px;
  left: 85px;
  transform: translate(-50%, -50%);
  height: 90px;
  width: 1px;
  z-index: 1;

  display: grid;
  grid-template-rows: repeat(3, 1fr)
}

.progressline {
  height: 100%;
  width: 100%;
}

.progressline_complete {
  border-left: 1px solid blue;
}

.progressline_active {
  border-left: 1px dashed blue;
}

.clickable_nav {
  color: #017698;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 900
}


/* END CUSTOM ORDERS */

.tag_flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

.more_width {
  width: 750px !important
}

.super_width {
  width: 1000px !important
}

.order_coffeebox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order_coffeebox img {
  width: 100%;
  margin: auto 0
}

.flexShrink {
  flex-shrink: 0;
}

.overflowX {
  overflow-x: auto;
}




.statusOptions {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.statusOptionsDate {
  width: 75%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.statusOuter {
  position: relative;
  height: 50px;
}

.statusInner {
  display: flex;
  column-gap: 0.5rem;
  position: absolute;
  margin-top:15px;
}

.closeStatusPopup {
  top: 20px;
  right: 20px;
}

button[disabled] {
  /* Add your disabled button styles here */
  cursor: default !important; /* Change to your desired disabled cursor style */
}

.box_line_break {
  height: 1px;
  background-color: black;
  opacity: .2;
  margin-top: 5px;
  box-sizing: border-box;
}

.popUpEmailBox tbody {
  height: auto !important;
  overflow-y: hidden !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.popUpEmailBox tr {
  display: table-row !important;
  overflow-y: hidden !important;
}

.popUpEmailBox tbody::-webkit-scrollbar {
  display: none !important;
}

.emailUploadedIcons {
  display: flex;
  column-gap: 0.35rem;
}

.popUpEmailBox {
  overflow: auto;
  max-height: 600px;
}

.emailUploadedIcons button {
  background: none;
  border: none;
}

.emailUploadedIcons button:hover {
  color: var(--green)
}

.emailUploadedIcons button i {
  font-size: 14px;
}

.emailPopupLabel {
  font-size: 11px !important;
}

.emailContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 2rem 0 2rem 0;
}

.linebreak {
  width: calc(100% - 1px);
  border: .5px solid var(--red_accent);
  margin: 1rem 0 1.5rem 0;
}

.popup_maxwidth {
  max-width: 90%;
  width: auto !important; 
}

.popUpExtendedWidth {
  width: 650px !important; 
}

.popup_maxheight {
  max-height: 75%;
  overflow-y: scroll
}

.popup_maxheight_allergies {
  max-height: 50%;
  height: 100%;
  overflow-y: scroll
}

.help_icon {
  margin: 0 0 0 auto;
  background: none;
  border: none;
  cursor: pointer;
}

.help_icon p {
  text-decoration: underline;
  font-weight: 600;
}

.dualLabels {
  display: flex;
}

.fileInputEmail {
  display: none;
}

.fileInputEmail::-webkit-file-upload-button {
  display: none;
}

.fileInputEmail::file-selector-button {
  display: none;
}

.fileInputTag {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 10px;
  display: flex;
  column-gap: .5rem;
  align-items: center;
}

.fileInputTag p,
.fileInputTag i {
  opacity: .7;
  color: hsl(0, 0%, 50%);
  font-size: 14px !important;
}

.holdLeft {
    margin: 0 25px 0 0;
}

.helpImgContainer {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpImgContainer img {
  object-fit: contain;
  width: 100%;
}

.fleet_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 150px;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-bottom:1rem
}

.fleet_container_prefs {
  display: flex;
/*  grid-template-columns: repeat(2, 1fr);*/
  column-gap: 1rem;
  row-gap: 1rem;
  margin-bottom:1rem;
  overflow-x: auto;
}

.fleet_tbody {
  display: block !important;
  height: calc(100% - 20px) !important;
  overflow-y: scroll !important;
}

.fleet_box_order {
  width: 200px;
  height: 125px;
  border-radius: 15px;
  cursor: pointer;
  background: white;
  border: none;
  overflow-y: auto;
  transition: .25s
}

.fleet_box_activeorder {
  background-color: var(--green) !important;
  transition: .25s
}

.fleet_box_activeorder > p {
  color: var(--white) !important;
  transition: .25s
}

.fleet_box_activeorder > div p {
  color: var(--white) !important;
  transition: .25s
}

.fleet_box_activeorder > .inner_order_content_top .site_statustd .site_status {
  background-color: var(--white) !important;
  transition: .25s
}

.fleet_box_activeorder > .inner_order_content_top .site_statustd .site_status p {
  color: var(--green) !important;
  transition: .25s
}

.sites_table_orders {
  display: flex;
  column-gap: 1rem;
}

.fleet_box {
  width: 100%;
  height: 150px;
  border-radius: 15px;
  cursor: pointer;
  background: white;
  border: none;
}

.fleet_td {
  padding: 0 !important
}

.inner_fleet_order {
  max-width: 100%;
/*  max-height: 100%;*/
/*  height: 100%;*/
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  display: grid;
/*  grid-template-rows: auto 1fr;*/
/*  row-gap: .75rem;*/
  position: relative;
  background-color: var(--grey_accent);
  border-radius: 10px;
/*  overflow-y: auto;*/
}

.inner_fleet_order p {
  white-space: normal;
  overflow-wrap: break-word;
  text-align: left
}

.inner_fleet {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  padding: 20px 20px 15px 20px;
  box-sizing: border-box;

  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: .75rem;
  position: relative;
  background-color: var(--grey_accent);
  border-radius: 10px;
  overflow-y: auto;
}

.fleet_img {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.fleet_headcontainer {
  display: flex;
}

.fleet_text {
   text-align: left;
   display: flex;
   width: 100%;
}

.fleet_text h1 {
  white-space: normal;
  overflow-wrap: break-word;
}

.fleet_status {
  margin: auto 0 auto auto;
  border-radius: 100%;
}


.siteControlsGroup {
  margin: 0 0 0 auto;
  column-gap: .5rem;
  display: flex;
}

.groupControlPopup {
  border: none;
  background: none;
}

.groupControlPopup i {
  margin: auto 0
}
.groupControlPopup:not([disabled]):hover {
  color: var(--green)
}

.siteGroups p {
  white-space: normal;
  overflow-wrap: break-word;
}

.innerIS {
  display: grid;
  grid-template-columns: 15px 1fr;
}


.radio_container {
  display: flex;
  column-gap: 1rem;
}

.align_bulk_radio {
  column-gap: 1rem;
  margin: auto 0
}

.radio_button {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio_button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.hidden_radio_container {
  cursor: default !important;
  opacity: .25;
  pointer-events:none !important;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  height: 14px;
  width: 14px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio_button:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio_button input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio_button input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio_button .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.draggableContainerBulk {
  height: calc(100% - 140px);
  width: 100%;

  padding: 0 2.5px;
  overflow: auto; 
}

.popup_form_fruitcustom {
  display: grid;
  grid-template-columns: 1fr 15% 15%;
  column-gap: 1.5rem;
  position: relative;
  width: 100%;
  padding: .5rem 0
}


.popup_form_custom {
  display: grid;
  grid-template-columns: 1fr 8% 8% 8% 8% 8% auto;
  column-gap: 1.5rem;
  position: relative;
  width: 100%;
  padding: .5rem 0
}

.menuControls {
  width: 100%;
  margin: 12.5px auto;
}

.addBtn {
  width: 100%;
  padding: 0;
  background-color: var(--white);
  color: var(--black);
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.form_controls {
  height: 100%;
  display: flex;
}

.form_controls button {
  background: none;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cw_pricing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 40%;
  white-space: normal;

  row-gap: .5rem;
}

.cw_pricing button {
  margin: 0 !important;
}

.page_container_birthday {
  background-image: url("../../../images/birthday_background.jpg") !important;
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover;
}

.custom_pref_layout {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: 1rem;
}

.custom_pref_layout_flag {
  margin-top: 1rem;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: 1rem;
  flex: 1
}

.box_layout_flexible {
  display: grid;
  grid-template-columns: 70% 1fr;
}

.box_layout_flexible_full {
  display: grid;
  grid-template-columns: auto auto 1fr;
  row-gap: .5rem;
  column-gap: 2rem;
}

.box_layout_full {
  width: 100%;
}

.box_max_layout {
  width: 100%;
  overflow-x: auto;
}

.inner_order_content_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marginOffset {
  margin: 0 0 auto 0;
}

.ifo_space {
  grid-template-rows: auto 1fr;
  row-gap: .5rem;
}

.ifo_height {
  height: auto !important;
}

.switchAddressType {
  background: none;
  border: none;
  width: max-content;
  margin: 0 0 0 auto;
}

.switchAddressType label {
  color: #017698;
  text-decoration: underline;
  font-weight: 900;
  cursor: pointer !important
}

.flaggedOrdersWarning {
  padding: 10px 20px;
  width: 111%;
  background-color: var(--red);
  box-sizing: border-box;
  margin-left: -5.5%;
  border: none;
  display: flex;
  align-items: center;
  justify-content:center;
  column-gap: .75rem;
}

.flaggedOrdersWarning p, .flaggedOrdersWarning i {
  color: var(--red_accent);
}

.red_flagged_item {
  background-color: var(--red) !important;
  color: var(--red_accent) !important;
}

.red_flagged_item:hover {
  background-color: var(--red_accent) !important;
  color: var(--red) !important; 
}

.customFlagError {
  margin-left: -30px;
  width: calc(100% + 60px);
  margin-bottom: 15px;
}

.inactiveDate {
  position: absolute;
  top: 60%;
}