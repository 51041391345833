@font-face {
 font-family: "LoewBold";
 src: local("LoewBold"), url("./fonts/Loew/Loew-Bold.otf") format("truetype");
 font-weight: 900;
}

@font-face {
 font-family: "Loew";
 src: local("Loew"), url("./fonts/Loew/Loew-ExtraBold.otf") format("truetype");
 font-weight: 800;
}

@font-face {
 font-family: "Loew";
 src: local("Loew"), url("./fonts/Loew/Loew-Heavy.otf") format("truetype");
 font-weight: 700;
}

@font-face {
 font-family: "Loew";
 src: local("Loew"), url("./fonts/Loew/Loew-Medium.otf") format("truetype");
 font-weight: 600;
}

@font-face {
 font-family: "Loew";
 src: local("Loew"), url("./fonts/Loew/Loew-Regular.otf") format("truetype");
 font-weight: 500;
}

@font-face {
 font-family: "Loew";
 src: local("Loew"), url("./fonts/Loew/Loew-Light.otf") format("truetype");
 font-weight: 400;
}

@font-face {
 font-family: "Loew";
 src: local("Loew"), url("./fonts/Loew/Loew-Thin.otf") format("truetype");
 font-weight: 300;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*:not(.fa-solid) {
  font-family: "Loew" !important;
}

h1, th, button {
	font-weight: 600 !important;
  outline: none
}

input:focus,
textarea:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #61B63F;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #61B63F none;
}

.MuiInputBase-input {
	border: none !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--red_accent) !important
}

.MuiIconButton-root {
	padding: 8px !important;
	margin-right: -8px !important;
}

.MuiButtonBase-root svg {
	width: 1rem !important;
	height: 1rem !important
}

.css-1fdsijx-ValueContainer {
  padding: 3.75px 8px !important
}

.css-1nmdiq5-menu {
  z-index: 999999 !important;
}

#deliveryNewModule .css-1nmdiq5-menu {
  width: 47.5% !important;
}

.mapboxgl-map {
  height: calc(100% - 5px) !important;
  width: 100% !important;
  border-radius: 10px;
}

.mapboxgl-canvas {
  border-radius: 10px
}

.mapboxgl-control-container {
  display: none !important;
}

.mapboxgl-canvas-container {
  position: relative;
}

.mapboxgl-marker {
  position: absolute;
  top: 0;
}

.css-qbdosj-Input input {
  padding: 0 !important;
  text-indent: 0 !important
}


.offline_container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -2.5%);
  top: 2.5%;
  
  z-index: 99999999;
}

.offline_inner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  background-color: white;
  padding: 12.5px 25px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.offline_container i {
  margin: auto 0;
  font-size: 35px;
  color: red;
}

.offline_content {
  display: flex;
  flex-direction: column;
}

.offline_container .offline_title {
  margin: 0;
  font-size: 17.5px;
  font-weight: bold;
  color: red
}

.offline_container .offline_subtitle {
  margin: 0;
  font-size: 12.5px;
  font-weight: 500;
  color: red
}