.indsite_popup {
  width: 40%;
  height: 100%;

  margin: 0 0 0 auto;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}

.rotateIcon {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 25px;
}

.popup_container_six {
  width: 800px;
  height: 600px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  border-radius: 10px;
}