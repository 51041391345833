.sidebar_container {
  height: 100%;
  width: 75px;

  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  transition: width 0.6s ease, grid-template-columns 0.6s ease;
}

.sidebar_container_full {
  height: 100%;
  width: 200px;

  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  transition: width 0.6s ease, grid-template-columns 0.6s ease;
}

.sidebar_account {
  position: absolute !important;
  top: 10px;
  height: 60px !important;
}

.sidebar_trash {
  position: absolute !important;
  bottom: 10px;
  height: 60px !important;
}

.sidebar_centered {
  height: auto;
  width: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sidebar_grid {
  width: 100%;

  display: grid;
  grid-template-rows: repeat(6, auto);
  row-gap: 1rem;
}

.sidebar_grid a {
  width: 100%;
  height: 60px;
  text-decoration:none;
}

.sidebar_box_full {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
  transition: background-color 0.3s ease;
}

.sidebar_box {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
  transition: background-color 0.3s ease;
}

.sidebar_box:hover {
  background-color: var(--green);
}

.sidebar_box:hover > .sidebar_icon_container .sidebar_icon {
  color: white;
}

.sidebar_box_full:hover {
  background-color: var(--green);
}

.sidebar_box_full:hover > .sidebar_icon_container .sidebar_icon_full,
.sidebar_box_full:hover > .sidebar_p {
  color: white;
}



.sidebar_active,
.sidebar_active i {
  background-color: var(--green);
  color: white;
}

.sidebar_icon_container {
  height: 100%;
  width: 100%;
  position: relative;
}

.sidebar_icon {
  font-size: 30px;
  color: var(--black);

  display:flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: color 0.3s ease
}

.sidebar_icon_full {
  font-size: 30px;
  color: var(--black);

  display:flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: color 0.3s ease
}

.sidebar_p {
  font-size: 15px;
  color: var(--black);
  transition: color 0.3s ease;
}


.text_fade {
  opacity: 1;
  transition: opacity 0.3s ease, width 0.3s ease color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
}

.text_hidden {
  opacity: 0;
  transition: opacity 0.3s ease, width 0.3s ease color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
}

.text_hidden_delay {
  opacity: 0;
  width: 0;
  transition: opacity 0.3s ease, width 0.3s ease color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  transition-delay: 0.3s; /* Delay to wait for the fade-out transition to complete */
}


.sidebar_active_p {
  color: var(--white);
}

.flexedNav {
  position: relative;
}


.collapsedContainer {
  position: absolute;
  top: 50%;
  right: -22.5px;
  transform: translate(0, -50%);
  z-index: 999999
}
.collapseMap {
  height: calc(100% - 30px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content:center;
  position: relative;
}

.collapseInner {
  height: 2rem;
  cursor: pointer;
}

.collapseOpacitated {
  opacity: 0.5;
}

.collapseBtn {
  background: none;
  border: none;
  height: 1rem;
  width: 0.25rem;
  background-color: #ffb71b;
  border-radius: 9999px;
  margin-right: .5rem;
}