.sites_table {
  border-collapse: collapse;

  width: 100%;
/*  height: 100%;*/
  text-align: left;
}

.table_rowth {
  display: table-header-group !important;
  background-color: white;
}

.table_bodyrowth {
  display: table-row-group !important;
}

.sites_table tr {
  counter-increment: counter;
  cursor: pointer;
  transition: .25s;
  display: table-row
}

.sites_table tr:hover:not(.table_rowth) {
  background-color: white;
}

.sites_table th {
  padding: 15px 20px;
  cursor: pointer !important;
  font-size: 12px !important;
  width: auto;
}

.sites_table th i {
  margin-left: .5rem;
}

.sites_table td {
  width: auto;
  padding: 30px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page_container p:not(.smaller_font, .small_font),
.sites_table td {
  font-size: 15px !important;
  margin: 0
}