/* RESET & BASIC STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --white: #ffffff;
  --black: #000000;
  --lightgrey: #F7F7F7;

  /* officefruit */
  --green: #61B63F;
  --green_accent: #DDF0D5;

  /* fruit */
  --red: #df0f36;
  --red_accent: #fce7eb;

  /* milk */
  --blue: #56bae5;
  --blue_accent: #E1EAEE;

  /* bread */
  --brown: #966014;
  --brown_accent: #f5efe8;

  /* coffee */
  --coffee: #be9546;
  --coffee_accent: #f9f4ed;

  /* cakes */
  --purple: #a927aa;
  --purple_accent: #f6e9f7;

  /* catering */
  --grey: #9b9a9a;
  --grey_accent: #f5f5f5;

  /* seasonal */
  --pink: #FF0090;
  --pink_accent: #FFE0E5;
}

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0 auto;
}

input:focus {
    outline: none;
}

button {
  cursor: pointer;
  transition: .25s
}

table tbody {
  display: block !important;
  height: calc(100% - 50px) !important;
  overflow-y: scroll !important
}

table thead, table tbody tr {
  display: table;
  width: 100% !important;
  table-layout: fixed !important;
}

/* START GENERAL */

.forbidden_text {
  line-height: 1;
  font-size: 25px !important;
}

.smaller_font {
  font-size: 12px !important;
}

.small_font {
  font-size: 13px !important;
}

.medium_font {
  font-size: 17.5px !important;
}

.bigger_font {
  font-size: 35px !important;
}

.sites_table th {
  font-size: 12px !important;
  cursor: default;
}

.page_container p:not(.smaller_font, .small_font, .forbidden_text),
.sites_table td {
  font-size: 15px !important;
  margin: 0
}

.page_container h1:not(.bigger_font),
.page_container h1:not(.medium_font) {
  font-size: 30px;
}


.main_container {
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: 75px 1fr;
}

.page_container {
  height: 100%;
  width: 100%;
  background-color: var(--lightgrey)
}

.hidden_container {
  opacity: 0.4 !important;
  filter: brightness(90%);
  pointer-events:none !important;
}

.hidediv {
  display: none !important;
}

.site_status {
  padding: 5px 15px;
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto 0 0 0;
}

.site_status p {
  font-weight: 600
}


.status_pending {
  background-color: var(--blue_accent);
  color: var(--blue);
}

.status_active {
  background-color: var(--green_accent);
  color: var(--green);
}

.status_inactive {
  background-color: var(--red_accent);
  color: var(--red);
}

.deleteBtn {
  background: none;
  border: none;
  transition: .25s;
  font-size: 15px;
}

.deleteBtn:hover {
  color: var(--green)
}

.indsite_container {
  position: absolute;
  right: 0;

  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.indsite_popup {
  width: 500px;
  height: 100%;

  margin: 0 0 0 auto;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}

.indsite_inner {
  padding: 30px;
  position: relative;
  height: calc(100% - 60px);
}

.ind_grid {
  display: grid;
  margin-top: 25px;
  grid-template-rows: repeat(2, 1fr);
  height: calc(100% - 50px);
}

.indinfo_grid {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem
}

.sitesind_thead th,
.sitesind_tr:hover {
  background-color: var(--grey_accent) !important;
}

.notification {
  padding: 10px;
  margin: 10px 0;
  color: white;
  text-align: center;
  font-weight: 600
}

.validationError {
  background-color: var(--red);
}

.warningMessage {
  background-color: var(--red_accent);
  color: var(--red);
}

.warningMessage span {
  text-decoration: solid underline red 1px;
}

/* END GENERAL */



/* START POPUP */

.popup_container {
  width: 500px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  border-radius: 10px;
}

.popup_relative {
  position: relative;
  height: 100%;
  width: 100%;
}

.popup_scrollable {
  max-height: 50vh;
  overflow-y: auto;
}

.popUpControlsContainer,
.popUpControls {
  display: flex;
  margin: 0 0 0 auto
}

.closePopup {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: white;
  border: none;
}

.closePopup i {
  margin: auto 0
}

.editPopup {
  position: absolute;
  top: 30px;
  right: 50px;
  background-color: white;
  border: none;
}

.editPopup i {
  margin: auto 0
}

.closePopup:hover,
.editPopup:hover {
  color: var(--green)
}

.prevBtn {
  padding: 7.5px 15px;
  background-color: var(--green_accent);
  color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.nextBtn,
.submitBtn {
  padding: 7.5px 15px;
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px
}

.popup_form {
  margin: 25px 0;
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 1rem
}

.popup_formorder {
  margin: 15px 0 25px 0;
  row-gap: 0;
}

.popup_form_nm {
  margin-top: 1rem;
  display:grid;
  row-gap: 1rem;
}

.popup_form_flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}

.popup_formbox {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.popup_formbox label {
  font-weight: 600;
  margin-bottom: 2.5px;
}

.popup_formbox input {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 10px
}

/* END POPUP */


/* START SIDEBAR */

.sidebar_container {
  height: 100%;
  width: 75px;

  position: relative;
}

.sidebar_account {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 20px);
}

.sidebar_centered {
  height: auto;
  width: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sidebar_grid {
  width: 100%;

  display: grid;
  grid-template-rows: repeat(4, 1fr);
  row-gap: 3rem;
}

.sidebar_box {
  width: 100%;
  height: 100%;
  padding: 15px 0;

  position: relative;
}

.sidebar_active,
.sidebar_active i {
  background-color: var(--green);
  color: white;
}

.sidebar_icon {
  font-size: 30px;
  color: var(--black);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* END SIDEBAR */


/* START SITES */

.sites_container {
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: 15% 22.5% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.sites_container_ongoing {
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: 15% 22.5% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.sitepage_container {
  height: 100%;
  border-bottom: 2px solid var(--white);

  position: relative;
}

.sitenav {
  display: flex;
  position: absolute;
  bottom: -2px;
}

.innersitenav {
  display: flex;
  margin-top: 15px;
}

.sitenav_box {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  transition: border-bottom-color 0.25s;
  border-bottom: 2px solid var(--white);

  position: relative;
}

.sitenav_box:hover {
  border-bottom: 2px solid var(--green)
}

.sitenav_active {
  border-bottom: 2px solid var(--green)
}

.page_heading {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.page_heading h1 {
  margin: 0 auto 0 0;
  width: auto;
}

.page_heading button {
  margin: 0 0 0 auto;
  padding: 7.5px 15px;
  background-color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.page_heading button p,
.page_heading button i {
  color: var(--white);
  font-weight: 600;
}

.page_heading button i {
  margin-right: 5px;
  font-size: 12px;
}

.page_heading button:hover {
  background-color: white;
  color: var(--green)
}

.page_heading button:hover p,
.page_heading button:hover i {
  color: var(--green)
}


.sites_stats {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem
}

.statbox {
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;
}

.sites_tablecontainer {
  height: 100%;
  width: 100%;
  border-radius: 15px;

  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 10px;
}

.table_container {
  height: 100%;
}

.sites_controls {
  display: flex;
  width: 100%;
}

.sites_filters {
  margin: 0 auto 0 0;
  display: flex;
}

.filter_add {
  padding: 7.5px 15px;
  border: 2px solid var(--white);
  border-radius: 5px;
  display: flex;

  align-items: center;
  justify-content:center;

  margin-right: 10px;
}

.filter_add p {
  margin: 0;
  font-weight: 600;
}

.filter_add i {
  font-size: 12px;
  margin: 0;
  margin-right: 7.5px;
}

.filter_active {
  padding: 7.5px 15px;
  background-color: var(--green_accent);
  border-radius: 5px;
  display: flex;

  align-items: center;
  justify-content:center;

  margin-right: 10px;

}

.filter_active p {
  margin: 0;
  color: var(--green);
  font-weight: 600;
  margin-right: 7.5px;
}

.filter_active i {
  font-size: 12px;
  margin: 0;
  color: var(--green)
}

.sites_searchcontainer {
  padding: 10px 0;
  width: 350px;
  background-color: var(--white);
  border-radius: 5px;

  display: flex;
  align-items: center;
}

.sites_search {
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%
}

.sites_search input {
  border: none;
  margin: auto 0;
  padding-left: 10px;
  font-weight: 600;
  width: 100%
}

.sites_search i {
  font-size: 12px;
  font-weight: 600;
}

.sites_sort {
  display: flex;
}

.sites_table {
  border-collapse: collapse;

  width: 100%;
  height: 100%;
  text-align: left;

}

.sites_table th {
  padding: 15px;
}

.sites_table tr {
  counter-increment: counter;
  cursor: pointer;
  transition: .25s
}

.sites_table tr:hover:not(.table_rowth) {
  background-color: white;
}

.table_rowth {
  background-color: white;
}


.sites_table td {
  width: auto;
  padding: 30px 20px;
}

.progressbar {
  padding: 5px 50px;
  border-radius: 100px;
  background: var(--green);
}

.temp_progressbar1 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 50%, var(--green_accent) 50%, var(--green_accent) 100%);
}

.temp_progressbar2 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 30%, var(--green_accent) 30%, var(--green_accent) 100%);
}

.temp_progressbar3 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 80%, var(--green_accent) 80%, var(--green_accent) 100%);
}

/* END SITES */


/* START CUSTOM ORDERS */

/* Fruit */
.fruit_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--red);
  color: var(--white) !important;
}

.fruit_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.fruit_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.fruit_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--red) !important;
}

.fruit_container .order_box_active:hover {
  background-color: var(--red)
}

.fruit_container .order_box_active:hover i,
.fruit_container .order_box_active:hover p:not(.site_status p),
.fruit_container .order_box_active:hover h1,
.fruit_container .order_box_active:hover label {
  color: var(--white)
}

.fruit_container .filter_active {
  background-color: var(--red_accent) !important;
}

.fruit_container .filter_active p,
.fruit_container .filter_active i {
  color: var(--red) !important
}

.fruit_container .sitenav_active {
  border-bottom: 2px solid var(--red)
}

.fruit_container .sitenav_box:hover {
  border-bottom: 2px solid var(--red)
}

.fruit_container .progressbar {
  background: linear-gradient(to right, var(--red) 0%, var(--red) 50%, var(--red_accent) 50%, var(--red_accent) 100%);
}

.fruit_container .progressdot,
.fruit_container .progressdot_box::before,
.fruit_container .progressdot_box_down::before,
.fruit_container .progressdot_box_up::before {
  background-color: var(--red)
}

.fruit_container .progress_text,
.fruit_container .sliderBtn {
  color: var(--red)
}

/* Milk */
.milk_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--blue);
  color: var(--white) !important;
}

.milk_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.milk_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.milk_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--blue) !important;
}

.milk_container .order_box_active:hover {
  background-color: var(--blue)
}

.milk_container .order_box_active:hover i,
.milk_container .order_box_active:hover p:not(.site_status p),
.milk_container .order_box_active:hover h1,
.milk_container .order_box_active:hover label {
  color: var(--white)
}

.milk_container .filter_active {
  background-color: var(--blue_accent) !important;
}

.milk_container .filter_active p,
.milk_container .filter_active i {
  color: var(--blue) !important
}

.milk_container .sitenav_active {
  border-bottom: 2px solid var(--blue)
}

.milk_container .sitenav_box:hover {
  border-bottom: 2px solid var(--blue)
}

.milk_container .progressbar {
  background: linear-gradient(to right, var(--blue) 0%, var(--blue) 50%, var(--blue_accent) 50%, var(--blue_accent) 100%);
}

.milk_container .progressdot,
.milk_container .progressdot_box::before,
.milk_container .progressdot_box_down::before,
.milk_container .progressdot_box_up::before {
  background-color: var(--blue)
}

.milk_container .progress_text,
.milk_container .sliderBtn {
  color: var(--blue)
}

/* Bread */
.bread_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--brown);
  color: var(--white) !important;
}

.bread_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.bread_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.bread_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--brown) !important;
}

.bread_container .order_box_active:hover {
  background-color: var(--brown)
}

.bread_container .order_box_active:hover i,
.bread_container .order_box_active:hover p:not(.site_status p),
.bread_container .order_box_active:hover h1,
.bread_container .order_box_active:hover label {
  color: var(--white)
}

.bread_container .filter_active {
  background-color: var(--brown_accent) !important;
}

.bread_container .filter_active p,
.bread_container .filter_active i {
  color: var(--brown) !important
}

.bread_container .sitenav_active {
  border-bottom: 2px solid var(--brown)
}

.bread_container .sitenav_box:hover {
  border-bottom: 2px solid var(--brown)
}

.bread_container .progressbar {
  background: linear-gradient(to right, var(--brown) 0%, var(--brown) 50%, var(--brown_accent) 50%, var(--brown_accent) 100%);
}

.bread_container .progressdot,
.bread_container .progressdot_box::before,
.bread_container .progressdot_box_down::before,
.bread_container .progressdot_box_up::before {
  background-color: var(--brown)
}

.bread_container .progress_text,
.bread_container .sliderBtn {
  color: var(--brown)
}

/* Coffee */
.coffee_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--coffee);
  color: var(--white) !important;
}

.coffee_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.coffee_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.coffee_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--coffee) !important;
}

.coffee_container .order_box_active:hover {
  background-color: var(--coffee)
}

.coffee_container .order_box_active:hover i,
.coffee_container .order_box_active:hover p:not(.site_status p),
.coffee_container .order_box_active:hover h1,
.coffee_container .order_box_active:hover label {
  color: var(--white)
}

.coffee_container .filter_active {
  background-color: var(--coffee_accent) !important;
}

.coffee_container .filter_active p,
.coffee_container .filter_active i {
  color: var(--coffee) !important
}

.coffee_container .sitenav_active {
  border-bottom: 2px solid var(--coffee)
}

.coffee_container .sitenav_box:hover {
  border-bottom: 2px solid var(--coffee)
}

.coffee_container .progressbar {
  background: linear-gradient(to right, var(--coffee) 0%, var(--coffee) 50%, var(--coffee_accent) 50%, var(--coffee_accent) 100%);
}

.coffee_container .progressdot,
.coffee_container .progressdot_box::before,
.coffee_container .progressdot_box_down::before,
.coffee_container .progressdot_box_up::before {
  background-color: var(--coffee)
}

.coffee_container .progress_text,
.coffee_container .sliderBtn {
  color: var(--coffee)
}

/* Cakes */
.cakes_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--purple);
  color: var(--white) !important;
}

.cakes_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.cakes_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.cakes_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--purple) !important;
}

.cakes_container .order_box_active:hover {
  background-color: var(--purple)
}

.cakes_container .order_box_active:hover i,
.cakes_container .order_box_active:hover p:not(.site_status p),
.cakes_container .order_box_active:hover h1,
.cakes_container .order_box_active:hover label {
  color: var(--white)
}

.cakes_container .filter_active {
  background-color: var(--purple_accent) !important;
}

.cakes_container .filter_active p,
.cakes_container .filter_active i {
  color: var(--purple) !important
}

.cakes_container .sitenav_active {
  border-bottom: 2px solid var(--purple)
}

.cakes_container .sitenav_box:hover {
  border-bottom: 2px solid var(--purple)
}

.cakes_container .progressbar {
  background: linear-gradient(to right, var(--purple) 0%, var(--purple) 50%, var(--purple_accent) 50%, var(--purple_accent) 100%);
}

.cakes_container .progressdot,
.cakes_container .progressdot_box::before,
.cakes_container .progressdot_box_down::before,
.cakes_container .progressdot_box_up::before {
  background-color: var(--purple)
}

.cakes_container .progress_text,
.cakes_container .sliderBtn {
  color: var(--purple)
}

/* Catering */
.catering_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--grey);
  color: var(--white) !important;
}

.catering_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.catering_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.catering_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--grey) !important;
}

.catering_container .order_box_active:hover {
  background-color: var(--grey)
}

.catering_container .order_box_active:hover i,
.catering_container .order_box_active:hover p:not(.site_status p),
.catering_container .order_box_active:hover h1,
.catering_container .order_box_active:hover label {
  color: var(--white)
}

.catering_container .filter_active {
  background-color: var(--grey_accent) !important;
}

.catering_container .filter_active p,
.catering_container .filter_active i {
  color: var(--grey) !important
}

.catering_container .sitenav_active {
  border-bottom: 2px solid var(--grey)
}

.catering_container .sitenav_box:hover {
  border-bottom: 2px solid var(--grey)
}

.catering_container .progressbar {
  background: linear-gradient(to right, var(--grey) 0%, var(--grey) 50%, var(--grey_accent) 50%, var(--grey_accent) 100%);
}

.catering_container .progressdot,
.catering_container .progressdot_box::before,
.catering_container .progressdot_box_down::before,
.catering_container .progressdot_box_up::before {
  background-color: var(--grey)
}

.catering_container .progress_text,
.catering_container .sliderBtn {
  color: var(--grey)
}

/* Seasonal */
.seasonal_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--pink);
  color: var(--white) !important;
}

.seasonal_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.seasonal_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.seasonal_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--pink) !important;
}

.seasonal_container .order_box_active:hover {
  background-color: var(--pink)
}

.seasonal_container .order_box_active:hover i,
.seasonal_container .order_box_active:hover p:not(.site_status p),
.seasonal_container .order_box_active:hover h1,
.seasonal_container .order_box_active:hover label {
  color: var(--white)
}

.seasonal_container .filter_active {
  background-color: var(--pink_accent) !important;
}

.seasonal_container .filter_active p,
.seasonal_container .filter_active i {
  color: var(--pink) !important
}

.seasonal_container .sitenav_active {
  border-bottom: 2px solid var(--pink)
}

.seasonal_container .sitenav_box:hover {
  border-bottom: 2px solid var(--pink)
}

.seasonal_container .progressbar {
  background: linear-gradient(to right, var(--pink) 0%, var(--pink) 50%, var(--pink_accent) 50%, var(--pink_accent) 100%);
}

.seasonal_container .progressdot,
.seasonal_container .progressdot_box::before,
.seasonal_container .progressdot_box_down::before,
.seasonal_container .progressdot_box_up::before {
  background-color: var(--pink)
}

.seasonal_container .progress_text,
.seasonal_container .sliderBtn {
  color: var(--pink)
}

.orders_current_container {
  height: 100%;
  width: 100%;
  display: grid;

  grid-template-columns: 50px calc(100% - 100px) 50px;
}

.orders_current_outer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.orders_current {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 1fr;
  column-gap: 2rem;
}

.sliderBtn {
  border: none;
  background: none;
  font-size: 20px;
}

.order_box,
.order_box_active,
.order_box_progress {
  background-color: var(--white);
  border-radius: 10px;
  padding: 15px;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
  border: none;
  text-align: left;
  position: relative;
}

.create_first {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.order_boxinfo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 1rem
}

.order_header {
  display: flex;
}

.order_header h1 {
  margin: 0 auto 0 0;
}

.order_header .popup_formbox {
  margin: 0 0 0 auto;
}

.site_contact_flex {
  display: flex
}

.site_contact_flex i {
  margin: auto 0;
  font-size: 12px;
  margin-right: 10px
}

.popup_formbox label,
.site_contact_flex label {
  font-weight: 600;
  margin-bottom: 2.5px;
}

.popup_formbox input,
.site_contact_flex input {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 10px
}

.site_statustd {
  display: flex;
}

.order_typebox {
  width: 100%;
  padding: 5px 10px 5px 0;
  border: 1px solid var(--red_accent);
  background: none;
  border-radius: 5px;
  text-align: left;

  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: .5rem;
}

.order_typebox:disabled {
  cursor: default
}

.order_typebox:enabled:hover {
  border: 1px solid var(--red);
}

.order_typeboxactive {
  border: 1px solid var(--red);
}

.order_imgbox {
  width: 100px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.order_typebox img {
  height: 100%;
  margin: auto 0
}

.order_contentbox {
  margin: auto 0
}

.progressmap {
  display: grid;
  grid-template-columns: auto 10% 1fr auto;
  grid-template-rows: repeat(4, 1fr);
  column-gap: .5rem
}

.progressmap p {
  font-weight: 700;
  margin: auto 0;
}

.progressdot_box,
.progressdot_box_up,
.progressdot_box_down {
  position: relative;
  height: 100%; /* adjust the height according to your needs */
  width: 100%;
}

.progressdot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: red;
  z-index: 2;
}

.progressdot_map {
  position: absolute;
  top: 100px;
  left: 87px;
  transform: translate(-50%, -50%);
  height: 90px;
  width: 1px;
  z-index: 1;

  display: grid;
  grid-template-rows: repeat(3, 1fr)
}

.progressline {
  height: 100%;
  width: 100%;
}

.progressline_complete {
  border-left: 1px solid blue;
}

.progressline_active {
  border-left: 1px dashed blue;
}

/* END CUSTOM ORDERS */









