/* RESET & BASIC STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --white: #ffffff;
  --black: #000000;
  --lightgrey: #F7F7F7;

  /* officefruit */
  --green: #61B63F;
  --green_accent: #DDF0D5;

  /* fruit */
  --red: #df0f36;
  --red_accent: #fce7eb;

  /* milk */
  --blue: #56bae5;
  --blue_accent: #E1EAEE;

  /* bread */
  --brown: #966014;
  --brown_accent: #f5efe8;

  /* coffee */
  --coffee: #be9546;
  --coffee_accent: #f9f4ed;

  /* cakes */
  --purple: #a927aa;
  --purple_accent: #f6e9f7;

  /* catering */
  --grey: #9b9a9a;
  --grey_accent: #f5f5f5;

  /* seasonal */
  --pink: #FF0090;
  --pink_accent: #FFE0E5;
}

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0 auto;
}

input:focus {
    outline: none;
}

button {
  cursor: pointer;
  transition: .25s
}

table tbody {
  display: block !important;
  height: calc(100% - 50px) !important;
  overflow-y: scroll !important
}

table thead, table tbody tr {
  display: table;
  width: 100% !important;
  table-layout: fixed !important;
}

/* START GENERAL */

.smaller_font {
  font-size: 12px !important;
}

.small_font {
  font-size: 13px !important;
}

.medium_font {
  font-size: 17.5px !important;
}

.bigger_font {
  font-size: 35px !important;
}

.sites_table th {
  font-size: 12px !important;
  cursor: default;
}

.page_container p:not(.smaller_font, .small_font),
.sites_table td {
  font-size: 15px !important;
  margin: 0
}

.page_container h1:not(.bigger_font),
.page_container h1:not(.medium_font) {
  font-size: 30px;
}



.main_container {
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: 75px 1fr;
  transition: grid-template-columns 0.6s ease
}

.main_container_full {
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: 200px 1fr;
  transition: grid-template-columns 0.6s ease
}

.page_container {
  height: 100%;
  width: 100%;
  background-color: var(--lightgrey)
}

.hidden_container {
  opacity: 0.4 !important;
  filter: brightness(90%);
  pointer-events:none !important;
}

.hidediv {
  display: none !important;
}

.site_status {
  padding: 5px 15px;
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto 0 0 0;
}

.site_right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
}

.site_status p {
  font-weight: 600;
  margin: auto 0
}


.status_pending {
  background-color: var(--blue_accent);
  color: var(--blue);
}

.status_active {
  background-color: var(--green_accent);
  color: var(--green);
}

.status_inactive {
  background-color: var(--red_accent);
  color: var(--red);
}

.deleteBtn {
  background: none;
  border: none;
  transition: .25s;
  font-size: 15px;
}

.deleteBtn:hover {
  color: var(--green)
}

.indsite_container {
  position: absolute;
  right: 0;

  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.indsite_popup {
/*  width: 500px;*/
  width: 40%;
  height: 100%;

  margin: 0 0 0 auto;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}

.invoice_popup {
  width: 70%;
/*  width: 850px;*/
  height: 100%;

  margin: 0 0 0 auto;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}

.indsite_inner {
  padding: 30px;
  position: relative;
  height: calc(100% - 60px);

  display: flex;
  flex-direction: column;
}

.ind_grid {
  display: grid;
  margin-top: 25px;
  grid-template-rows: repeat(2, 1fr);
  height: calc(100% - 50px);
}

.indinfo_grid {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem
}

.indsiteinfo_gap {
  row-gap: 2rem
}

.sitesind_thead th,
.sitesind_tr:hover {
  background-color: var(--grey_accent) !important;
}

.notification {
  padding: 10px;
  margin: 10px 0;
  color: white;
  text-align: center;
  font-weight: 600
}

.validationError {
  background-color: var(--red);
}

.confirmMessage {
  background-color: var(--green_accent);
  color: var(--green);
}

.confirmMessage p {
  font-weight: bold
}

.warningMessage {
  background-color: var(--red_accent);
  color: var(--red);
}

.warningMessage span {
  text-decoration: solid underline red 1px;
}

/* END GENERAL */



/* START POPUP */

.popup_container {
  width: 500px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  border-radius: 10px;
}

.popup_container_six {
  width: 1100px;
  height: 600px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  border-radius: 10px;
}

.popup_container_custom {
  width: 75%;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  border-radius: 10px;
}


.popup_relative {
  position: relative;
  height: 100%;
  width: 100%;
}

.popup_scrollable {
  max-height: 50vh;
  overflow-y: auto;
}

.popUpControlsContainer,
.popUpControls {
  display: flex;
  margin: 0 0 0 auto
}

.closePopup {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: white;
  border: none;
}

.closePopup i {
  margin: auto 0
}

.editPopup {
  position: absolute;
  top: 30px;
  right: 50px;
  background-color: white;
  border: none;
}

.editPopup i {
  margin: auto 0
}

.nextPopup {
  position: absolute;
  top: 30px;
  right: 70px;
  background-color: white;
  border: none;
}

.nextPopup i {
  margin: auto 0
}

.pdfPopup {
  position: absolute;
  top: 30px;
  right: 90px;
  background-color: white;
  border: none;
}

.pdfPopup i {
  margin: auto 0
}

.emailPopup {
  position: absolute;
  top: 30px;
  right: 70px;
  background-color: white;
  border: none;
}

.emailPopup i {
  margin: auto 0
}

.closePopup:hover,
.editPopup:hover,
.pdfPopup:hover,
.emailPopup:hover,
.nextPopup:hover {
  color: var(--green)
}

.prevBtn {
  padding: 7.5px 15px;
  background-color: var(--green_accent);
  color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.nextBtn,
.submitBtn {
  padding: 7.5px 15px;
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px
}

.popup_form {
  margin: 25px 0;
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 1rem
}

.toolsFlex {
  display: flex;
  column-gap: .5rem;
}

.toolsBtn {
  background: none;
  border: none;
}

.toolsBtn i {
  margin: auto 0
}

.toolsBtn:hover {
  color: var(--green)
}


.invoice_grid {
  width:100%;
  display: grid;
  grid-template-columns: 1fr 25% 25% repeat(3, 1fr) auto;
  column-gap: 1rem 
}

.popup_form_invoice {
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 1rem 
}

.popup_form_margin {
  margin: 25px 0;
}

.invoice_grid_menu {
  margin: 27.5px 0 auto 0;
  cursor: pointer;
  display: flex;
  column-gap: .5rem
}

.invoice_grid_menu button {
  border: none;
  background: none;
}

.invoice_grid_menu i,
.invoice_grid_menu button i {
  font-size: 20px;
}

.add_item {
  margin-top: .75rem;
  background: none;
  border: none;
  display: flex;
  column-gap: .25rem;
  color: var(--green);
}

.add_item i {
  margin: auto 0 
}

.popup_formorder {
  margin: 15px 0 25px 0;
  row-gap: 0;
}

.popup_form_nm {
  margin-top: 1rem;
  display:grid;
  row-gap: 1rem;
}

.popup_form_flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}

.popup_form_multi {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}

.popup_formbox {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.popup_formbox label {
  font-weight: 600;
  margin-bottom: 2.5px;
}

.popup_formbox input {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 10px
}

.invoice_formbox input {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 0;
  width: 100%;
  text-indent: 10px
}

.invoice_formbox {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.invoice_formbox label {
  font-weight: 600;
  margin-bottom: 2.5px;
}

/* END POPUP */


/* START SIDEBAR */

.sidebar_container {
  height: 100%;
  width: 75px;

  position: relative;
}

.sidebar_account {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);

  width: 75px;
  height: 60px;
}

.sidebar_trash {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -10px);

  width: 75px;
  height: 60px;
}

.sidebar_centered {
  height: auto;
  width: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sidebar_grid {
  width: 100%;

  display: grid;
  grid-template-rows: repeat(6, auto);
  row-gap: 1rem;
}

.sidebar_grid a {
  width: 100%;
  height: 60px;
}

.sidebar_box {
  width: 100%;
  height: 100%;

  position: relative;
}

.sidebar_active,
.sidebar_active i {
  background-color: var(--green);
  color: white;
}

.sidebar_icon {
  font-size: 30px;
  color: var(--black);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* END SIDEBAR */


/* START SITES */

.sites_container {
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: 15% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.sites_container_ongoing {
  height: 100%;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-rows: 15% 22.5% 1fr;
  row-gap: 1.5rem;
  margin: 0 auto;
}

.sitepage_container {
  height: 100%;
  border-bottom: 2px solid var(--white);

  position: relative;
}

.sitenav {
  display: flex;
  position: absolute;
  bottom: -2px;
}

.innersitenav {
  display: flex;
  margin-top: 15px;
}

.indsite_nav {
  position: relative;
  height: 50px;
  margin-bottom: 20px;
}

.sitenav_box {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  transition: border-bottom-color 0.25s;
  border-bottom: 2px solid var(--white);

  position: relative;
}

.sitenav_box:hover {
  border-bottom: 2px solid var(--green)
}

.sitenav_active {
  border-bottom: 2px solid var(--green)
}

.page_heading {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.page_heading h1 {
  margin: 0 auto 0 0;
  width: auto;
}

.page_heading button {
  margin: 0 0 0 auto;
  padding: 7.5px 15px;
  background-color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.page_heading button p,
.page_heading button i {
  color: var(--white);
  font-weight: 600;
  margin: auto 0;
}

.page_heading button i {
  margin-right: 5px;
  font-size: 12px;
}

.page_heading button:hover {
  background-color: white;
  color: var(--green)
}

.page_heading button:hover p,
.page_heading button:hover i {
  color: var(--green)
}


.sites_stats {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem
}

.statbox {
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;
}

.sites_tablecontainer {
  height: 100%;
  max-width: 100%;
/*  border-radius: 15px;*/

  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 10px;

  flex-grow: 1;
}

.sites_tablecontainer_auto {
  height: 100%;
  max-width: 100%;
/*  border-radius: 15px;*/

  display: grid;
  grid-template-rows: auto auto auto 1fr;
  row-gap: 10px;

  flex-grow: 1;
}

.extra_margin_bottom {
  margin-top: 0.5rem
}

.invoice_generation {
  height: 100%;
}

.linebreak {
  width: 100%;
  border: .5px solid var(--red_accent);
  margin: 3rem 0;
}

.invoice_items_container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.inv_tablecontainer {
  height: 100%;
  max-width: 100%;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
}

.table_container {
  height: 100%;
}

.indtbody {
  position: relative;
}

.indtbody_cus {
  position: relative;
  height: calc(100% - 75px) !important
}

.sites_controls {
  display: flex;
  width: 100%;
}

.sites_filters {
  margin: 0 auto 0 0;
  display: flex;
}

.filter_add {
  padding: 7.5px 15px;
  border: 2px solid var(--white);
  border-radius: 5px;
  display: flex;

  align-items: center;
  justify-content:center;

  margin-right: 10px;
}

.filter_add p {
  margin: 0;
  font-weight: 600;
}

.filter_add i {
  font-size: 12px;
  margin: 0;
  margin-right: 7.5px;
}

.filter_active {
  padding: 7.5px 15px;
  background-color: var(--green_accent);
  border-radius: 5px;
  display: flex;

  align-items: center;
  justify-content:center;

  margin-right: 10px;

}

.filter_active p {
  margin: 0;
  color: var(--green);
  font-weight: 600;
  margin-right: 7.5px;
}

.filter_active i {
  font-size: 12px;
  margin: 0;
  color: var(--green)
}

.admin_searchcontainer {
  padding: 10px 0;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;

  display: flex;
  align-items: center;
}

.sites_searchcontainer {
  padding: 10px 0;
  width: 350px;
  background-color: var(--white);
  border-radius: 5px;

  display: flex;
  align-items: center;
}

.sites_search {
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%
}

.sites_search input {
  border: none;
  margin: auto 0;
  padding-left: 10px;
  font-weight: 600;
  width: 100%;
  font-size: 15px;
}

.sites_search i {
  font-size: 15px;
  font-weight: 600;
}

.sites_sort {
  display: flex;
}

.sites_table {
  border-collapse: collapse;

  width: 100%;
  height: 100%;
  text-align: left;

}

.sites_table th {
  padding: 15px;
}

.sites_table tr {
  counter-increment: counter;
  cursor: pointer;
  transition: .25s
}

.nohover {
  cursor: default !important;
}

.sites_table tr:hover:not(.table_rowth, .nohover) {
  background-color: white;
}

.table_rowth {
  background-color: white;
}


.sites_table td {
  width: auto;
  padding: 30px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tool_btn {
  background: none;
  border: none;
}

.tool_btn:nth-child(2) {
  margin-left: 10px;
}


.tool_btn i {
  font-size: 15px;
}

.tool_btn:hover {
  color: var(--green)
}

.progressbar {
  padding: 5px 50px;
  border-radius: 100px;
  background: var(--green);
}

.temp_progressbar1 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 50%, var(--green_accent) 50%, var(--green_accent) 100%);
}

.temp_progressbar2 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 30%, var(--green_accent) 30%, var(--green_accent) 100%);
}

.temp_progressbar3 {
  background: linear-gradient(to right, var(--green) 0%, var(--green) 80%, var(--green_accent) 80%, var(--green_accent) 100%);
}

.indsitebtn {
  margin: 0 0 0 auto;
  padding: 7.5px 15px;
  background-color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.indsitebtn p,
.indsitebtn i {
  color: var(--white);
  font-weight: 600;
}

.indsitebtn i {
  margin-right: 5px;
  font-size: 12px;
}

.indsitebtn:hover {
  background-color: white;
  color: var(--green)
}

.indsitebtn:hover p,
.indsitebtn:hover i {
  color: var(--green)
}

.indsite_controls_wh {
  white-space: nowrap;
  align-items: center;
}

.autowidth {
  width: 100%;
}

/* END SITES */


/* START CUSTOM ORDERS */

/* Fruit */
.fruit_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--red);
  color: var(--white) !important;
}

.fruit_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.fruit_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.fruit_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--red) !important;
}

.fruit_container .order_box_active:hover {
  background-color: var(--red)
}

.fruit_container .order_box_active:hover i,
.fruit_container .order_box_active:hover p:not(.site_status p),
.fruit_container .order_box_active:hover h1,
.fruit_container .order_box_active:hover label {
  color: var(--white)
}

.fruit_container .filter_active {
  background-color: var(--red_accent) !important;
}

.fruit_container .filter_active p,
.fruit_container .filter_active i {
  color: var(--red) !important
}

.fruit_container .sitenav_active {
  border-bottom: 2px solid var(--red)
}

.fruit_container .sitenav_box:hover {
  border-bottom: 2px solid var(--red)
}

.fruit_container .progressbar {
  background: linear-gradient(to right, var(--red) 0%, var(--red) 50%, var(--red_accent) 50%, var(--red_accent) 100%);
}

.fruit_container .progressdot,
.fruit_container .progressdot_box::before,
.fruit_container .progressdot_box_down::before,
.fruit_container .progressdot_box_up::before {
  background-color: var(--red)
}

.fruit_container .progress_text,
.fruit_container .sliderBtn {
  color: var(--red)
}

/* Milk */
.milk_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--blue);
  color: var(--white) !important;
}

.milk_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.milk_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.milk_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--blue) !important;
}

.milk_container .order_box_active:hover {
  background-color: var(--blue)
}

.milk_container .order_box_active:hover i,
.milk_container .order_box_active:hover p:not(.site_status p),
.milk_container .order_box_active:hover h1,
.milk_container .order_box_active:hover label {
  color: var(--white)
}

.milk_container .filter_active {
  background-color: var(--blue_accent) !important;
}

.milk_container .filter_active p,
.milk_container .filter_active i {
  color: var(--blue) !important
}

.milk_container .sitenav_active {
  border-bottom: 2px solid var(--blue)
}

.milk_container .sitenav_box:hover {
  border-bottom: 2px solid var(--blue)
}

.milk_container .progressbar {
  background: linear-gradient(to right, var(--blue) 0%, var(--blue) 50%, var(--blue_accent) 50%, var(--blue_accent) 100%);
}

.milk_container .progressdot,
.milk_container .progressdot_box::before,
.milk_container .progressdot_box_down::before,
.milk_container .progressdot_box_up::before {
  background-color: var(--blue)
}

.milk_container .progress_text,
.milk_container .sliderBtn {
  color: var(--blue)
}

/* Bread */
.bread_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--brown);
  color: var(--white) !important;
}

.bread_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.bread_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.bread_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--brown) !important;
}

.bread_container .order_box_active:hover {
  background-color: var(--brown)
}

.bread_container .order_box_active:hover i,
.bread_container .order_box_active:hover p:not(.site_status p),
.bread_container .order_box_active:hover h1,
.bread_container .order_box_active:hover label {
  color: var(--white)
}

.bread_container .filter_active {
  background-color: var(--brown_accent) !important;
}

.bread_container .filter_active p,
.bread_container .filter_active i {
  color: var(--brown) !important
}

.bread_container .sitenav_active {
  border-bottom: 2px solid var(--brown)
}

.bread_container .sitenav_box:hover {
  border-bottom: 2px solid var(--brown)
}

.bread_container .progressbar {
  background: linear-gradient(to right, var(--brown) 0%, var(--brown) 50%, var(--brown_accent) 50%, var(--brown_accent) 100%);
}

.bread_container .progressdot,
.bread_container .progressdot_box::before,
.bread_container .progressdot_box_down::before,
.bread_container .progressdot_box_up::before {
  background-color: var(--brown)
}

.bread_container .progress_text,
.bread_container .sliderBtn {
  color: var(--brown)
}

/* Coffee */
.coffee_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--coffee);
  color: var(--white) !important;
}

.coffee_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.coffee_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.coffee_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--coffee) !important;
}

.coffee_container .order_box_active:hover {
  background-color: var(--coffee)
}

.coffee_container .order_box_active:hover i,
.coffee_container .order_box_active:hover p:not(.site_status p),
.coffee_container .order_box_active:hover h1,
.coffee_container .order_box_active:hover label {
  color: var(--white)
}

.coffee_container .filter_active {
  background-color: var(--coffee_accent) !important;
}

.coffee_container .filter_active p,
.coffee_container .filter_active i {
  color: var(--coffee) !important
}

.coffee_container .sitenav_active {
  border-bottom: 2px solid var(--coffee)
}

.coffee_container .sitenav_box:hover {
  border-bottom: 2px solid var(--coffee)
}

.coffee_container .progressbar {
  background: linear-gradient(to right, var(--coffee) 0%, var(--coffee) 50%, var(--coffee_accent) 50%, var(--coffee_accent) 100%);
}

.coffee_container .progressdot,
.coffee_container .progressdot_box::before,
.coffee_container .progressdot_box_down::before,
.coffee_container .progressdot_box_up::before {
  background-color: var(--coffee)
}

.coffee_container .progress_text,
.coffee_container .sliderBtn {
  color: var(--coffee)
}

/* Cakes */
.cakes_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--purple);
  color: var(--white) !important;
}

.cakes_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.cakes_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.cakes_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--purple) !important;
}

.cakes_container .order_box_active:hover {
  background-color: var(--purple)
}

.cakes_container .order_box_active:hover i,
.cakes_container .order_box_active:hover p:not(.site_status p),
.cakes_container .order_box_active:hover h1,
.cakes_container .order_box_active:hover label {
  color: var(--white)
}

.cakes_container .filter_active {
  background-color: var(--purple_accent) !important;
}

.cakes_container .filter_active p,
.cakes_container .filter_active i {
  color: var(--purple) !important
}

.cakes_container .sitenav_active {
  border-bottom: 2px solid var(--purple)
}

.cakes_container .sitenav_box:hover {
  border-bottom: 2px solid var(--purple)
}

.cakes_container .progressbar {
  background: linear-gradient(to right, var(--purple) 0%, var(--purple) 50%, var(--purple_accent) 50%, var(--purple_accent) 100%);
}

.cakes_container .progressdot,
.cakes_container .progressdot_box::before,
.cakes_container .progressdot_box_down::before,
.cakes_container .progressdot_box_up::before {
  background-color: var(--purple)
}

.cakes_container .progress_text,
.cakes_container .sliderBtn {
  color: var(--purple)
}

/* Catering */
.catering_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--grey);
  color: var(--white) !important;
}

.catering_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.catering_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.catering_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--grey) !important;
}

.catering_container .order_box_active:hover {
  background-color: var(--grey)
}

.catering_container .order_box_active:hover i,
.catering_container .order_box_active:hover p:not(.site_status p),
.catering_container .order_box_active:hover h1,
.catering_container .order_box_active:hover label {
  color: var(--white)
}

.catering_container .filter_active {
  background-color: var(--grey_accent) !important;
}

.catering_container .filter_active p,
.catering_container .filter_active i {
  color: var(--grey) !important
}

.catering_container .sitenav_active {
  border-bottom: 2px solid var(--grey)
}

.catering_container .sitenav_box:hover {
  border-bottom: 2px solid var(--grey)
}

.catering_container .progressbar {
  background: linear-gradient(to right, var(--grey) 0%, var(--grey) 50%, var(--grey_accent) 50%, var(--grey_accent) 100%);
}

.catering_container .progressdot,
.catering_container .progressdot_box::before,
.catering_container .progressdot_box_down::before,
.catering_container .progressdot_box_up::before {
  background-color: var(--grey)
}

.catering_container .progress_text,
.catering_container .sliderBtn {
  color: var(--grey)
}

/* Seasonal */
.seasonal_container button:not(.sitenav_box, .order_typebox, .closePopup, .deleteBtn, .order_typebox, .order_box_active, .sliderBtn) {
  background-color: var(--pink);
  color: var(--white) !important;
}

.seasonal_container button:not(.deleteBtn, .sitenav_box, .closePopup, .nextBtn, .prevBtn, .submitBtn, .order_typebox, .overridebg button, .order_box_active, .sliderBtn):hover {
  background-color: var(--white) !important;
}

.seasonal_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover i,
.seasonal_container button:not(.deleteBtn, .nextBtn, .prevBtn, .submitBtn, .sitenav_box, .closePopup, .order_typebox, .overridebg button, .order_box_active):hover p {
  color: var(--pink) !important;
}

.seasonal_container .order_box_active:hover {
  background-color: var(--pink)
}

.seasonal_container .order_box_active:hover i,
.seasonal_container .order_box_active:hover p:not(.site_status p),
.seasonal_container .order_box_active:hover h1,
.seasonal_container .order_box_active:hover label {
  color: var(--white)
}

.seasonal_container .filter_active {
  background-color: var(--pink_accent) !important;
}

.seasonal_container .filter_active p,
.seasonal_container .filter_active i {
  color: var(--pink) !important
}

.seasonal_container .sitenav_active {
  border-bottom: 2px solid var(--pink)
}

.seasonal_container .sitenav_box:hover {
  border-bottom: 2px solid var(--pink)
}

.seasonal_container .progressbar {
  background: linear-gradient(to right, var(--pink) 0%, var(--pink) 50%, var(--pink_accent) 50%, var(--pink_accent) 100%);
}

.seasonal_container .progressdot,
.seasonal_container .progressdot_box::before,
.seasonal_container .progressdot_box_down::before,
.seasonal_container .progressdot_box_up::before {
  background-color: var(--pink)
}

.seasonal_container .progress_text,
.seasonal_container .sliderBtn {
  color: var(--pink)
}

.orders_current_container {
  height: 100%;
  width: 100%;
  display: grid;

  grid-template-columns: 50px calc(100% - 100px) 50px;
}

.orders_current_outer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.orders_current {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 1fr;
  column-gap: 2rem;
}

.sliderBtn {
  border: none;
  background: none;
  font-size: 20px;
}

.order_box,
.order_box_active,
.order_box_progress {
  background-color: var(--white);
  border-radius: 10px;
  padding: 15px;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
  border: none;
  text-align: left;
  position: relative;
}

.create_first {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
}


.create_first2 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order_boxinfo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 1rem
}

.order_header {
  display: flex;
}

.order_header h1 {
  margin: 0 auto 0 0;
}

.order_header .popup_formbox {
  margin: 0 0 0 auto;
}

.site_contact_flex {
  display: flex
}

.site_contact_flex i {
  margin: auto 0;
  font-size: 12px;
  margin-right: 10px
}

.popup_formbox label,
.site_contact_flex label {
  font-weight: 600;
  margin-bottom: 2.5px;
}

.popup_formbox input,
.site_contact_flex input {
  border: 1px solid var(--red_accent);
  border-radius: 5px;
  padding: 10px 10px
}

.site_statustd {
  display: flex;
}

.order_typebox {
  width: 100%;
  padding: 5px 10px 5px 0;
  border: 1px solid var(--red_accent);
  background: none;
  border-radius: 5px;
  text-align: left;

  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: .5rem;
}

.order_typebox:disabled {
  cursor: default;
}

.order_catbox:disabled {
  cursor: default;
  opacity: .25
}

.order_typebox:enabled:hover {
  border: 1px solid var(--red);
}

.order_typeboxactive {
  border: 1px solid var(--red);
}


.order_catbox {
  width: 100%;
  padding: 5px 10px 5px 0;
  border: none;
  background: none;
  border-radius: 5px;
  text-align: left;
  display: flex;
  justify-content: center;
}

.order_catimgbox {
  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.order_catimgbox img {
  height: 100%;
  margin: auto 0
}

.order_catbox:enabled:hover {
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}

.order_catboxactive {
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
}


.order_imgbox {
  width: 100px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.order_imgbox img {
  height: 100%;
  margin: auto 0
}

.order_contentbox {
  margin: auto 0
}

.progressmap {
  display: grid;
  grid-template-columns: auto 10% 1fr auto;
  grid-template-rows: repeat(4, 1fr);
  column-gap: .5rem
}

.progressmap p {
  font-weight: 700;
  margin: auto 0;
}

.progressdot_box,
.progressdot_box_up,
.progressdot_box_down {
  position: relative;
  height: 100%; /* adjust the height according to your needs */
  width: 100%;
}

.progressdot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: red;
  z-index: 2;
}

.progressdot_map {
  position: absolute;
  top: 100px;
  left: 85px;
  transform: translate(-50%, -50%);
  height: 90px;
  width: 1px;
  z-index: 1;

  display: grid;
  grid-template-rows: repeat(3, 1fr)
}

.progressline {
  height: 100%;
  width: 100%;
}

.progressline_complete {
  border-left: 1px solid blue;
}

.progressline_active {
  border-left: 1px dashed blue;
}

/* END CUSTOM ORDERS */

.load span {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin: 0 5px;
  background-color: #000000;
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation: 1.2s infinite bubble ease-in-out;
  animation: 1.2s infinite bubble ease-in-out;
}
.load span:nth-child(1) {
  background-color: #61B63F;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.load span:nth-child(2) {
  background-color: #75C057;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.load span:nth-child(3) {
  background-color: #89C96F;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.load span:nth-child(4) {
  background-color: #B0DB9F;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@keyframes bubble {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

.dual_buttons {
  display: flex;
  column-gap: 1rem;
}

.loadpage_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.custom_tbody {
  height: calc(100% - 100px) !important;
}

.invoice_tbody {
  height: 300px !important;
}

.total_container {
  display: flex;
  column-gap: 3rem;
}

.total_container .theading_container {
  margin: 0 0 0 auto;
}

.theading_container p {
  font-weight:bold
}

.popup_form_customdate {
  margin: 25px 0;
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.popup_form_customdate_inv {
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.label_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.5rem;
}

.order_iconbox {
  width: 60px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.order_iconbox i {
  margin: auto 0;
  font-size: 35px;
}

.sites_search_custom {
  display: flex;
  align-items: center;
  width: 100%;
}

.sites_search_custom input {
  border: none;
  margin: auto 0;
  padding-left: 10px;
  font-weight: 600;
  width: 100%;
  font-size: 15px;
}

.sites_search_custom i {
  font-size: 15px;
  font-weight: 600;
}

.lock_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%);
  opacity: .4;
}

/*.custom_height {
  height: calc(100% - 50px) !important
}*/


.fleet_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 200px;
  column-gap: 1rem;
  row-gap: 1rem;

  height: 100% !important;
  overflow-y: scroll !important;
}

.fleet_box {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  cursor: pointer;
  background: white;
  border: none;
}


.inner_fleet {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  padding: 20px 20px 15px 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  position: relative;
}

.fleet_img {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.van {
  width: 100%;
  flex-grow: 1;
}

.truck {
  width: 75%;
  flex-grow: 1;
}

.mileage {
  margin: 0 0 0 auto
}

.damage_grid {
  height: 100%;
  width: 100%
}

.damageimg {
  width: 500px;
  position: relative;
}

.damageimg img {
  width: 100%;
}

.damage_icon {
  position: absolute;
}

.removeIcon {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.fleet_headcontainer {
  display: flex;
}

.fleet_text {
   text-align: left;
}

.fleet_status {
  margin: auto 0 auto auto;
  border-radius: 100%;
}

.fleet_green {
  background-color: var(--green)
}

.fleet_flag {
  background-color: red !important;
}

.fleet_flag p,
.fleet_flag h1,
.fleet_flag i {
  color: white;
}

.fleet_red i {
  color: var(--red);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px
}

.map_outer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.indfleetgrid {
  grid-template-columns: repeat(3, 1fr);
}

.fleet_tablecontainer {
  height: 100%;
  width: 100%;
  border-radius: 15px;

  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2rem;

  flex-grow: 1;
}

.popup_moreheight {
  width: 600px
}

.inner_user {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  padding: 20px 20px 15px 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  position: relative;

}

.user_headcontainer {
  display: flex;
  column-gap: 1rem;
  position: relative
}

.user_outer {
  height: 100%;
  display: flex;
  align-items: center;
}

.user_outer i {
  font-size: 30px;
}

.user_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 175px;
  column-gap: 1rem;
  row-gap: 1rem;

/*  height: 100% !important;*/
/*  overflow-y: scroll !important;*/
}

.userd_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 175px;
  column-gap: 1rem;
  row-gap: 1rem;
}

.user_box {
  width: 100%;
  height: 175px;
  border-radius: 15px;
  cursor: pointer;
  background: white;
  border: none;
}

.user_permissions { 
  display: flex;
  column-gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  row-gap: 0.5rem;
}

.user_stats_driver { 
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: flex-start
}

.induser_permissions {
  display: flex;
  column-gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 0.1rem;
  row-gap: 0.5rem;
}

.user_status {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto 0 0 0;
}

.user_status p {
  font-weight: 600
}

.status_CustomerService {
  background-color: var(--green_accent);
  color: var(--green);
}

.status_Warehouse {
  background-color: var(--red_accent);
  color: var(--red);
}

.status_Fleet {
  background-color: var(--blue_accent);
  color: var(--blue);
}

.status_Invoices {
  background-color: var(--coffee_accent);
  color: var(--coffee);
}

.status_Reports {
  background-color: var(--pink_accent);
  color: var(--pink);
}
/*
green
green_accent
red
red_accent
blue
blue_accent
brown
brown_accent
coffee
coffee_accent
purple
purple_accent
grey
grey_accent
pink
pink_accent*/

.statusOptions {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.statusOuter {
  position: relative;
  height: 50px;
}

.statusInner {
  display: flex;
  column-gap: 0.5rem;
  position: absolute;
  margin-top:15px;
}

.closeStatusPopup {
  top: 20px;
  right: 20px;
}


.fleet_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 200px;
  column-gap: 1rem;
  row-gap: 1rem;
}

.fleet_tbody {
  display: block !important;
  height: calc(100% - 20px) !important;
  overflow-y: scroll !important;
}

.fleet_box {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  cursor: pointer;
  background: white;
  border: none;
}

.fleet_td {
  padding: 0 !important
}

.clickable_nav {
  color: #017698;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 900
}

.emailUploadedIcons {
  display: flex;
  column-gap: 0.35rem;
}

.emailUploadedIcons button {
  background: none;
  border: none;
}

.emailUploadedIcons button:hover {
  color: var(--green)
}

.emailUploadedIcons button i {
  font-size: 14px;
}

.currDateSel {
    text-align: center;
    margin-top: 10px;
    padding: 15px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 20px auto 20px
}

.currDateSel p {
    margin: 0;
}

.currDateSel button {
    background: none;
    border: none;
}


.box_layout {
  display: flex;
  column-gap: .5rem;
}

.box_active {
  background-color: var(--green) !important;
  color: var(--green_accent) !important;
}



.indsitebtn_driver {
  margin: 0 0 0 auto;
  padding: 7.5px 15px;
  background-color: var(--green);
  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.indsitebtn_driver p,
.indsitebtn_driver i {
  color: var(--white);
  font-weight: 600;
}

.indsitebtn_driver i {
  margin-right: 5px;
  font-size: 12px;
}

.indsitebtn_driver:disabled {
  background-color: var(--grey) !important;
  opacity: .5;
  cursor: not-allowed !important;
}

.indsitebtn_driver:disabled p,
.indsitebtn_driver:disabled i {
  color: var(--white) !important;
}

.indsitebtn_driver:hover {
  background-color: white;
  color: var(--green)
}

.indsitebtn_driver:hover p,
.indsitebtn_driver:hover i {
  color: var(--green)
}