/* RESET & BASIC STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --white: #fff;
  --black: #333;
  --darkblue: #001d4e;
  --lightblue: #E5F1FA;
  --skyblue: #009ddf;
  --table-border: #dde7ea;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Loew";
/*  color: var(--darkblue)*/
}

body {
  margin: 0 auto;
}

input:focus {
    outline: none;
}


.bg {
  height: 100vh !important;
  background-color: #E6F5FC;
}

.container {
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.outerSquare {
  width: 100%;
  height: 85%;

  background-color: white;
  box-shadow: 10px 10px 40px rgba(0,0,0,0.2);
  background-blend-mode: multiply;

  display: flex;
}

.logo {
  padding: 30px;
  position: absolute;
  height: 50px;
}

.leftSide {
  width: 40%;
  padding: 30px;
}

.leftSide h1 {
  color: #124559;
  font-size: 24px;
  margin-top: 6rem;
}

.leftSide p {
  color: #124559;
  font-size: 14px;
  margin-top: .25rem;
}

.leftSide form {
  margin-top: 3rem;
}

.formWrapper {
  position: relative;
}

.formWrapper label {
  position: absolute;
  padding: 10px;
  font-size: 10px
}

.formWrapper input {
  border: 2px solid #D2F0FC;
  border-bottom-width: 0;
  width: 100%;
  height: 4rem;
  padding: 12.5px 10px 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: #0077B6;
  border-left-color: #0077B6;
  
}

.input_line {
  border: 2px solid #D2F0FC !important;
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;
}


.controls {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fgtPwd {
  color: #124559;
  font-size: 10px;
  cursor: pointer !important;
  margin: 5px 0 0 auto;
}


.field {
  width: 100%;
}

.checkbox {
  color: #124559;
  font-size: 10px;
  display: flex;
}

.field .checkbox input[type="checkbox"] {
  height: 15px;
  width: 15px;
  border: 1px solid #124559 !important;
  border-radius: 0 !important
}

.checkbox p {
  color: #124559;
  font-size: 10px;
  margin: 0 0 0 10px;
}


.leftSide form button[type="submit"] {
  border: none;
  margin-top: 1rem;
  width: 100px;
  height: 35px;

  text-align: center;
  font-size: 12px;
  background-color: #0077B6;
  color: white;
}


.followus {
  margin-top: 3rem;
}

.followus,
.socials {
  display: flex;
}

.followus p {
  padding-right: 25px;
  color: #0077B6;
  opacity: .7;
}

.socials p {
  padding-right: 15px !important;
  color: #0077B6;
  opacity: 1 !important;
}

.socials a {
  cursor: pointer;
  text-decoration: none;
}


.notification {
  padding: 10px;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  font-size: 12px;
  box-sizing: border-box;
}

.notification p {
  color: white;
  margin: 0
}

.error {
  background-color: #f15d43 !important;
}

.success {
  background-color: #5cb85c !important
}







.rightSide {
  width: 60%;
  height: 100%;

  background-color: #E6F5FC;
  margin: 0 0 0 auto;

  display: flex;
  align-items: center; 
  justify-content: center;
}


.citySvg {
  width: 90%
}


@media only screen and (max-width: 1120px) {
  .rightSide {
    width: 500px;
  }

  .citySvg {
    height: 40%
  }
}

@media only screen and (max-width: 888px) {
  .rightSide {
    background-color: white !important
  }

  .citySvg {
    display: none
  }
}

